import React from 'react'
import Layout from 'components/Layout'

const CloudComputingServicePage = ({ location }) => (
  <Layout
    title="All the tools to unlock your data"
    description="Use UseData to connect to any data source (Redshift, BigQuery, MySQL, PostgreSQL, MongoDB and many others), query, visualize and share your data to make your company data driven."
    location={location}
  >
    <section className="section section-product-hero">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-md-offset-2 text-center m-b-100">
            <h1 className="serif">Cloud Professional Services</h1>
            <p>
              We help companies make a shift to digital transformation with the power of cloud
            </p>
          </div>
        </div>

        <div className="row">
          <h3 className="m-t-0 feature_font service-container">
            <span className="m-r-15 feature_icon"><i className="fa fa-bolt" aria-hidden="true"></i></span> Capabilities
          </h3>          
          <div className="col-md-6">
            <div className="featured-customer case-study-item">
              <div className="row">
                <div className="col-md-4 service-capability">
                  <img
                    src="/assets/images/elements/computing_server.svg"
                    alt="Server Computing"
                    className="customer-logo"
                  />                  
                </div>
                <div className="col-md-8">
                  <h2>Computing</h2>
                  <ul className="highlight-ul">
                    <li>Virtual Machines (VMs)</li>
                    <li>Containers</li>
                    <li>Serverless</li>
                    <li>Web Apps</li>
                    <li>Network & Content Delievery</li>
                    <li>Security</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="featured-customer case-study-item">
              <div className="row">
                <div className="col-md-4 service-capability">
                  <img
                    src="/assets/images/elements/datastores.svg"
                    alt="Data Stores"
                    className="customer-logo"
                  />                  
                </div>
                <div className="col-md-8">
                  <h2>Data Stores</h2>
                  <ul className="highlight-ul">
                    <li>Relational</li>
                    <li>Caching</li>
                    <li>NoSQL</li>
                    <li>Document</li>
                    <li>Time Series</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="featured-customer case-study-item">
              <div className="row">
                <div className="col-md-4 service-capability">
                  <img
                    src="/assets/images/elements/cloud_analytics.svg"
                    alt="Cloud Analytics"
                    className="customer-logo"
                  />                  
                </div>
                <div className="col-md-8">
                  <h2>Analytics</h2>
                  <ul className="highlight-ul">
                    <li>Data Warehouses</li>
                    <li>Business Intelligence & Reports</li>
                    <li>SQL Query</li>
                    <li>Search Service</li>
                    <li>Workflows</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="featured-customer case-study-item">
              <div className="row">
                <div className="col-md-4 service-capability">
                  <img
                    src="/assets/images/elements/cloud_ai.svg"
                    alt="Machine Learning Cloud AI"
                    className="customer-logo"
                  />                  
                </div>
                <div className="col-md-8">
                  <h2>Machine Learning & AI</h2>
                  <ul className="highlight-ul">
                    <li>Forecast</li>
                    <li>Vision AI</li>
                    <li>Text Comprehend</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>                    

        </div>
      </div>
    </section>

    <section className="section bg-soft-yellow">
      <div className="container">
        <div className="row">
          <h3 className="m-t-0 feature_font text-right service-container">
            <span className="m-r-15 feature_icon"><i className="fa fa-cogs" aria-hidden="true"></i></span> Cloud Services
          </h3>          
          <div className="col-md-4">
            <div className="featured-customer case-study-item">
              <h2>AWS</h2>
              <ul className="highlight-ul">
                <li>Amazon EC2</li>
                <li>Amazon Elastic Container Service</li>
                <li>Amazon S3</li>
                <li>Amazon Route 53</li>
                <li>Amazon API Gateway</li>
                <li>Amazon RDS</li>
                <li>Amazon ElastiCache</li>
                <li>Amazon Redshift</li>
                <li>Amazon Athena</li>
                <li>Amazon SageMaker</li>
                <li>Amazon Comprehend</li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="featured-customer case-study-item">
              <h2>Google Cloud</h2>
              <ul className="highlight-ul">
                <li>Vision AI</li>
                <li>Cloud Natural Language</li>
                <li>BigQuery</li>
                <li>Cloud Dataflow</li>
                <li>Cloud Dataproc</li>
                <li>Google Data Studio</li>
                <li>Cloud SQL</li>
                <li>Cloud BigTable</li>
                <li>Compute Engine</li>
                <li>Google Kubernetes Engine</li>
              </ul>
            </div>
          </div>

          <div className="col-md-4">
            <div className="featured-customer case-study-item">
              <h2>DigitalOcean</h2>
              <ul className="highlight-ul">
                <li>Droplets</li>
                <li>Kubernetes</li>
                <li>Cloud Firewalls</li>
                <li>Load Balancers</li>
                <li>Virtual Private Cloud</li>
                <li>Managed Relational Databases</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="section">
      <div className="container">
        <h3 className="m-t-0 feature_font text-center mb-xs">
          <span className="m-r-15 feature_icon"><i className="fa fa-thumbs-up" aria-hidden="true"></i></span> More Cloud, More Value
        </h3>
        <p className="m-t-0 text-center mb-md">
          Cloud services helps businesses increase operational efficiency and collaboration as well as improve innovation
        </p>
        <div className="row">
          <div className="col-md-4">
            <img src="/assets/images/elements/business_efficiency.svg" width="64px" height="64px"/>
            <h2>Operational Efficiency</h2>
            <p>
              Organizations with high levels of cloud adoption (more than half of infrastructure and apps in the cloud) are much more likely to see multiple quantifiable improvements in customer satisfaction, efficiency, and cost savings than those with lower levels.
            </p>
          </div>

          <div className="col-md-4">
            <img src="/assets/images/elements/hybrid_cloud.svg" width="64px" height="64px"/>
            <h2>Hybrid Cloud Adoption</h2>
            <p>
              The need of integration of multiple cloud services to simplify implementation and continuous performance improvements.
            </p>
          </div>

          <div className="col-md-4">
            <img
              src="/assets/images/elements/pro_team.svg"
              width="64px"
              height="64px"
            />
            <h2>High Quality Cloud Professionals</h2>
            <p>
              Providing an appropriate and high-quality professionals, who can blend industry expertise with business and IT skills to deliver customer success, has become a crucial factor for a successful cloud project.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="section section--pt-sm bg-soft-blue">
      <div className="container">
        
      </div>
    </section>

  </Layout>
)

export default CloudComputingServicePage
